import { default as aboutg3dIqs1tpGMeta } from "/builds/bogutskyy/belarus-border-web/pages/about.vue?macro=true";
import { default as _91code_93n91dMbA4jyMeta } from "/builds/bogutskyy/belarus-border-web/pages/checkpoint/[code].vue?macro=true";
import { default as indexOUxKdHmF5DMeta } from "/builds/bogutskyy/belarus-border-web/pages/index.vue?macro=true";
import { default as _91code_93UzjDf42TnXMeta } from "/builds/bogutskyy/belarus-border-web/pages/tlgrm/checkpoint/[code].vue?macro=true";
import { default as indexJoxb4MsWfPMeta } from "/builds/bogutskyy/belarus-border-web/pages/tlgrm/index.vue?macro=true";
export default [
  {
    name: aboutg3dIqs1tpGMeta?.name ?? "about",
    path: aboutg3dIqs1tpGMeta?.path ?? "/about",
    meta: aboutg3dIqs1tpGMeta || {},
    alias: aboutg3dIqs1tpGMeta?.alias || [],
    redirect: aboutg3dIqs1tpGMeta?.redirect || undefined,
    component: () => import("/builds/bogutskyy/belarus-border-web/pages/about.vue").then(m => m.default || m)
  },
  {
    name: _91code_93n91dMbA4jyMeta?.name ?? "checkpoint-code",
    path: _91code_93n91dMbA4jyMeta?.path ?? "/checkpoint/:code()",
    meta: _91code_93n91dMbA4jyMeta || {},
    alias: _91code_93n91dMbA4jyMeta?.alias || [],
    redirect: _91code_93n91dMbA4jyMeta?.redirect || undefined,
    component: () => import("/builds/bogutskyy/belarus-border-web/pages/checkpoint/[code].vue").then(m => m.default || m)
  },
  {
    name: indexOUxKdHmF5DMeta?.name ?? "index",
    path: indexOUxKdHmF5DMeta?.path ?? "/",
    meta: indexOUxKdHmF5DMeta || {},
    alias: indexOUxKdHmF5DMeta?.alias || [],
    redirect: indexOUxKdHmF5DMeta?.redirect || undefined,
    component: () => import("/builds/bogutskyy/belarus-border-web/pages/index.vue").then(m => m.default || m)
  },
  {
    name: _91code_93UzjDf42TnXMeta?.name ?? "tlgrm-checkpoint-code",
    path: _91code_93UzjDf42TnXMeta?.path ?? "/tlgrm/checkpoint/:code()",
    meta: _91code_93UzjDf42TnXMeta || {},
    alias: _91code_93UzjDf42TnXMeta?.alias || [],
    redirect: _91code_93UzjDf42TnXMeta?.redirect || undefined,
    component: () => import("/builds/bogutskyy/belarus-border-web/pages/tlgrm/checkpoint/[code].vue").then(m => m.default || m)
  },
  {
    name: indexJoxb4MsWfPMeta?.name ?? "tlgrm",
    path: indexJoxb4MsWfPMeta?.path ?? "/tlgrm",
    meta: indexJoxb4MsWfPMeta || {},
    alias: indexJoxb4MsWfPMeta?.alias || [],
    redirect: indexJoxb4MsWfPMeta?.redirect || undefined,
    component: () => import("/builds/bogutskyy/belarus-border-web/pages/tlgrm/index.vue").then(m => m.default || m)
  }
]