interface TelegramWindow {
    Telegram: {
        WebApp: {
            initData: String;
            initDataUnsafe: {
                query_id: String;
                user: {
                    id: Number;
                };
            };
            ready: Function;
            expand: Function;
            isExpanded: Boolean;
            showAlert: Function;
        }
    }
}

export default defineNuxtPlugin(async (nuxtApp) => {
    if ('tlgrm' === nuxtApp._route.name) {
        await import('./../vendors/telegram-web-app');
    }

    return {
        provide: {
            telegramWebApp: (window as any as TelegramWindow).Telegram?.WebApp,
        }
    };
});