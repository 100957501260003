import * as Sentry from "@sentry/vue";

export default defineNuxtPlugin((nuxtApp) => {
    const { env, release, sentry } = useAppConfig();

    if (!sentry.dsn) {
        return
    }

    const ignoreErrors = [
        '<no response> Load failed',
        '<no response> Failed to fetch',
        '<no response> signal is aborted without reason',
        '<no response> The operation was aborted',
        '<no response> Request signal is aborted',
        'The user aborted a request',
        'TypeError: Load failed',
        'Fetch is aborted',
        'Failed to fetch dynamically imported module',
    ];

    Sentry.init({
        app: nuxtApp.vueApp,
        dsn: sentry.dsn,
        environment: env || 'production',
        integrations: [],
        enableTracing: false,
        release: 'belarusborder-web@' + release,
        ignoreErrors,
    })
});