<script setup lang="ts">
import type {NuxtError} from "#app/composables/error";

const props = defineProps({
  error: {
    type: Object as () => NuxtError,
    required: true
  }
});
const error = props.error;

const { t } = useI18n();

useHead({
  title: error?.statusCode === 404
      ? t('error.title.not_found')
      : t('error.title.error')
});
</script>

<template>
  <NuxtLayout>
    <div class="pt-2">
      <ErrorNotFound
        v-if="error?.statusCode === 404"
      />
      <div
        v-else
        class="row g-4"
      >
        <div class="text-center">
          <h1>{{ error?.statusCode || 500 }}</h1>
          <h2>{{ t('error.application_error') }}</h2>
        </div>
      </div>
    </div>
  </NuxtLayout>
</template>
