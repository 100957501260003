export default defineAppConfig({
  env: import.meta.env.VITE_ENVIRONMENT,
  release: import.meta.env.VITE_RELEASE,
  api: {
    baseUrl: import.meta.env.VITE_API_BASE_URL,
  },
  echo: {
    env: import.meta.env.VITE_ECHO_ENV,
    host: import.meta.env.VITE_ECHO_HOST,
  },
  sentry: {
    dsn: import.meta.env.VITE_SENTRY_DSN,
  },
  telegram: {
    bot_link: import.meta.env.VITE_TELEGRAM_BOT_LINK
  },
});