import Echo from 'laravel-echo';
import io from 'socket.io-client';

export default defineNuxtPlugin(() => {
    const { env, echo: { env: echoEnv, host: echoHost } } = useAppConfig();

    const echo = new Echo({
        broadcaster: 'socket.io',
        host: echoHost || window.location.hostname,
        client: io
    });

    return {
        provide: {
            echo,
            echoChannel: (channel: string) => echo.channel((echoEnv || env) + '.' + channel)
        }
    };
});