<script setup lang="ts">
const { t } = useI18n();

defineProps({
  message: {
    type: String,
    default: null
  }
});
</script>

<template>
  <div class="row g-4">
    <div class="text-center">
      <h1>404</h1>
      <h2>{{ message || t('error.page_not_found') }}</h2>
    </div>
  </div>
</template>
